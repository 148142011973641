import { authenticationFactory } from 'hirunner-common/authentication';
import { ROUTE_LOGIN } from '../constants/routes';

const {
	isAuthenticated,
	authenticationService,
	SecureRoute,
	basicAuthentication
} = authenticationFactory({
	storageKey: '_hirunner_web_',
	clientName: 'hirunner-runner-ui',
	loginRoute: ROUTE_LOGIN,
	userDetailsApi: '/api/v1/me',
});

export {
	isAuthenticated,
	authenticationService,
	SecureRoute,
	basicAuthentication
};
