import { apiFactory } from 'hirunner-common/api';
import { authenticationService } from '../authentication';
import { ROUTE_LOGIN } from '../constants/routes';

const {
	axios,
	LogoutHandler
} = apiFactory({ authenticationService, ROUTE_LOGIN });

export { LogoutHandler };
export default axios;
