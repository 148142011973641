import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { AlertCircle, CheckCircle } from 'react-feather';

export default ({ confirmationState }) => (
	<Container fluid id="confirm-email">
		<Row className="justify-content-md-center">
			<Col xs md={4}>
				<div className="mt-5">
					<Card>
						<Card.Body>
							{confirmationState === 'pending' && (
								<>
									<Card.Title as="h4" className="text-center">Email-Bestätigung läuft...</Card.Title>
									<Card.Text className="text-center">
										Bitte warte einen kurzen Augenblick, während wir deine Email-Bestätigung abschließen...
									</Card.Text>
								</>
							)}
							{confirmationState === 'success' && (
								<>
									<Card.Title as="h4" className="text-center">Email-Adresse bestätigt</Card.Title>
									<Card.Text className="text-center">
										<CheckCircle color="#0afde7" size={48} className="error" />
										Deine Email-Adresse wurde erfolgreich bestätigt! Viel Spaß bei HiRunner!
									</Card.Text>
								</>
							)}
							{confirmationState === 'error' && (
								<>
									<Card.Title as="h4" className="text-center">Fehler bei der Bestätigung</Card.Title>
									<Card.Text className="text-center">
										<AlertCircle color="#ff547e" size={48} className="error" />
										Leider konnten wir deine Email-Adresse nicht bestätigen. Bitte versuche es noch einmal.
									</Card.Text>
								</>
							)}
						</Card.Body>
					</Card>
				</div>
			</Col>
		</Row>
	</Container>
);
