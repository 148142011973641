import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default () => (
	<Container id="use-the-app">
		<Row className="justify-content-md-center">
			<Col xs={12} md={6} lg={4}>
				<img src={require('../../assets/images/HiRunner_Logo_Full_RGB.svg')} alt="HiRunner Logo" />
			</Col>
		</Row>
		<h3 className="text-center">
			Bitte lade dir die HiRunner Mobile App herunter!
		</h3>
		<p className="text-center">
			<a className="store-link" href="https://apps.apple.com/de/app/hirunner/id1552493336">
				<img src={require('../../assets/images/apple-app-store-badge.png')} alt="The Apple app store badge" />
			</a>
			<a className="store-link" href="https://play.google.com/store/apps/details?id=co.hirunner">
				<img src={require('../../assets/images/google-play-badge.png')} alt="The Google Play badge" />
			</a>
		</p>
		<p className="text-center">
			<a href="https://hirunner.co">Zurück zur Startseite</a>
		</p>
	</Container>
);
