import React, { useState, useEffect } from 'react';
import qs from 'qs';
import ConfirmEmailView from './ConfirmEmailView';
import axios from '../../api';

export default ({ location }) => {
	const [confirmationState, setConfirmationState] = useState('pending');
	const code = qs.parse(location.search, { ignoreQueryPrefix: true }).code;

	useEffect(() => {
		const confirmEmailAsync = async () => {
			if (!code) {
				setConfirmationState('error');
				return;
			}
			try {
				await axios.post('/emailconfirmation', {
					code,
				});
				setConfirmationState('success');
			} catch (_) {
				setConfirmationState('error');
			}
		};
		confirmEmailAsync();
	}, [code]);

	return (
		<ConfirmEmailView confirmationState={confirmationState} />
	);

};
