import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const apiFactory = ({ authenticationService, loginRoute }) => {

	const instance = axios.create({

		baseURL: '/api/v1',
		responseType: 'json'

	});

	instance.interceptors.request.use(function (config) {
		const accessToken = authenticationService.currentAuthenticationValue?.access_token;
		if (accessToken) {
			config.headers.Authorization = 'Bearer ' + accessToken;
		}
		return config;
	});

	class UndecoratedLogoutHandler extends React.Component {

		componentDidMount() {
			this.responseInterceptor = instance.interceptors.response.use(
				undefined,
				this.handle401
			);
		}

		componentWillUnmount() {
			instance.interceptors.response.eject(this.responseInterceptor);
		}

		handle401 = error => {
			if (error.response.status === 401) {
				authenticationService.logout().then(() => {
					this.props.history.push(loginRoute);
				});
			}
			return Promise.reject(error);
		}

		render() {
			return this.props.children;
		}

	}

	const LogoutHandler = withRouter(UndecoratedLogoutHandler);

	return {
		axios: instance,
		LogoutHandler
	};
};

export { apiFactory };
