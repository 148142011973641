import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScrollToTop from 'hirunner-common/components/ScrollToTop';

import UseTheApp from './routes/use-the-app';
import ConfirmEmail from './routes/confirm-email';

import * as ROUTES from './constants/routes';

import './styles/styles.scss';

function App() {
	return (
		<Router>
			<ScrollToTop />
			<Switch>
				<Route path={ROUTES.ROUTE_HOME} exact component={UseTheApp} />
				<Route path={ROUTES.ROUTE_CONFIRM_EMAIL} exact component={ConfirmEmail} />
			</Switch>
		</Router>
	);
}

export default App;
