import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import querystring from 'querystring';

const authenticationFactory = ({ storageKey, clientName, loginRoute, userDetailsApi }) => {

	let refreshTimer = null;

	const currentAuthenticationSubject = new BehaviorSubject(loadCurrentAuthenticationFromStorage());

	const basicAuthentication = {
		username: clientName,
		password: ''
	};

	function loadCurrentAuthenticationFromStorage() {
		try {
			const data = JSON.parse(localStorage.getItem(storageKey));
			if (data && data.user && data.access_token && data.refresh_token) {
				const deltaToExpiry = data.expiresAt - Date.now();
				if (deltaToExpiry <= 0) {
					console.log('@@@@@@@@ stored auth is already expired');
					return null;
				}
				refreshTimer = setTimeout(refreshToken, deltaToExpiry);
				return data;
			}
		} catch (_) {
		}
		return null;
	}

	function login(email, password, loadedUser = null) {
		return axios.post('/api/oauth/token', querystring.stringify({
			username: email,
			password,
			grant_type: 'password'
		}), {
			auth: basicAuthentication,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}).then(({ data: tokens }) => {
			// Setup refresh handler
			refreshTimer = setTimeout(refreshToken, 1000 * (tokens.expires_in - 10));
			// Fetch user details
			let promise;
			if (loadedUser) {
				promise = new Promise((resolve) => {
					resolve({ data: loadedUser });
				});
			} else {
				promise = axios.get(userDetailsApi, {
					headers: {
						Authorization: 'Bearer ' + tokens.access_token
					}
				});
			}
			return promise.then(({ data: user }) => {
				const result = {
					...tokens,
					expiresAt: Date.now() + tokens.expires_in * 1000,
					user: user.data
				};
				localStorage.setItem(storageKey, JSON.stringify(result));
				currentAuthenticationSubject.next(result);
				return result;
			});
		});
	}

	function logout() {
		return new Promise(function(resolve, reject) {
			clearTimeout(refreshTimer);
			refreshTimer = null;
			localStorage.removeItem(storageKey);
			if (currentAuthenticationSubject.value) {
				axios.post('/api/v1/users/logout', null, {
					headers: {
						Authorization: 'Bearer ' + currentAuthenticationSubject.value.access_token
					}
				});
			}
			currentAuthenticationSubject.next(null);
			resolve();
		});
	}

	function refreshToken() {
		const currentAuthentication = currentAuthenticationSubject.value;
		if (!currentAuthentication || !currentAuthentication.refresh_token) {
			return;
		}
		const promise = axios.post('/api/oauth/token', querystring.stringify({
			grant_type: 'refresh_token',
			refresh_token: currentAuthentication.refresh_token
		}), {
			auth: basicAuthentication,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});
		promise.then(({ data: tokens }) => {
			refreshTimer = setTimeout(refreshToken, 1000 * (tokens.expires_in - 10))
			const result = {
				...currentAuthentication,
				expiresAt: Date.now() + tokens.expires_in * 1000,
				...tokens
			};
			currentAuthenticationSubject.next(result);
			localStorage.setItem(storageKey, JSON.stringify(result));
			return result;
		}, () => {
			window.location.href = '/login';
		});
		return promise;
	}

	function isAuthenticated() {
		const currentUser = currentAuthenticationSubject.value;
		const result = !!(currentUser && currentUser.user && currentUser.access_token && currentUser.refresh_token);
		return result;
	}

	const authenticationService = {
		login,
		logout,
		currentAuthentication: currentAuthenticationSubject.asObservable(),
		get currentAuthenticationValue() {
			return currentAuthenticationSubject.value
		}
	};

	const SecureRoute = ({ component: Component, ...rest }) => (
		<Route {...rest} render={(props) => (
			isAuthenticated()
				? <Component {...props} />
				: <Redirect to={{
					pathname: loginRoute,
					state: { from: props.location }
				}} />
		)} />
	);

	return {
		isAuthenticated,
		authenticationService,
		SecureRoute,
		basicAuthentication
	};
};

export { authenticationFactory };
